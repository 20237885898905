import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required, maxLength } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useDepartment() {
  // Misc
  const { t } = useI18n();

  const { ALL_OPTIONS, defaultStatusOptions, getDepartments } = useOptions();

  // CONSTANTS
  const view_code = "Departments";
  const logType = "Department";
  const route = "administration-departments";
  const endpoint = "administration.departments";
  const searchFields = ["name"];
  const rules = {
    name: { required },
    code: { required, maxLength: maxLength(5) },
    details: {},
    colour: {},
    external_code: {},
    department_id: {},
    status: { required }
  };
  const types = {
    name: inputTypes.TEXT,
    code: inputTypes.TEXT,
    details: inputTypes.TEXT,
    colour: inputTypes.COLOR,
    external_code: inputTypes.TEXT,
    department_id: inputTypes.SELECT,
    status: inputTypes.SELECT
  };
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.departments", 1),
      value: "name"
    },
    {
      text: t("app.code"),
      value: "code",
      headerClass: "justify-center",
      class: "text-center"
    },
    {
      text: t("app.users", 2),
      value: "user_count",
      headerClass: "justify-end",
      class: "text-right"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    name: null,
    code: null,
    details: "",
    colour: "",
    external_code: null,
    department_id: "",
    status: ALL_OPTIONS.ACTIVE.value
  });
  const options = ref({
    department_id: [],
    status: defaultStatusOptions
  });

  // METHODS
  const getOptions = async () => {
    options.value.department_id = await getDepartments();

    return options.value;
  };

  return {
    headers,
    logType,
    route,
    endpoint,
    searchFields,
    value,
    options,
    rules,
    types,
    view_code,
    getOptions,
    getDepartments
  };
}
